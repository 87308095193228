<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="600px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" dark v-on="on">
                Agregar
                <v-icon right>mdi-plus-box-outline</v-icon>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Actividad de Bienestar Animal" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" >
                        <v-textarea 
                            v-model="actividad.actividad"
                            label="Actividad Realizada"
                            @input="$v.actividad.$touch()"
                            counter="70"
                            :error-messages="descripErrors"
                            required>
                        </v-textarea>
                    </v-col>
                    <v-col cols="6" md="6" class="pb-0">
                        <v-select
                            :items="opciones"
                            item-text="nombre"
                            item-value="valor"
                            v-model="actividad.incluida"
                            label="Incluída en formulario"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="agregarActEstrellaNaranja">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import { required, requiredIf, decimal, maxLength } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * (1024 * 1024);
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.evidencias != null ? this.evidencias.length : 0;
        },
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        opcionErrors(){
            const errors = []
            if (!this.$v.iniciativa.opcion.$dirty) return errors
            !this.$v.iniciativa.opcion.required && errors.push('Campo requerido')
            return errors 
        },
        descripErrors() {
            const errors = []
            if (!this.$v.actividad.actividad.$dirty) return errors            
                !this.$v.actividad.actividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        actividad: {
            actividad: '',
            incluida: '',
        },
        opciones:[
            {nombre:'Si', valor: 1},
            {nombre:'No', valor: 0},
        ],
    }),
    validations: {
        actividad: {
            incluida: { required },
            actividad: {
                maxLength: maxLength(70),
            },
        },
    },
    methods: {
        cancelar() {
            this.actividad = {};
            this.dialog = false;
            this.loading = false;
            this.$v.actividad.$reset();
        },
        agregarActEstrellaNaranja() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append("actividad", this.actividad.actividad);
                formData.append("incluida", this.actividad.incluida);
                formData.append("idInstalacion", this.idInstalacion);
                formData.append("idUsuario", this.idUsuario);
                formData.append("annio", this.anioConsulta);
                axios.post("/estrellas/agregaActividadNaranja", formData)
                .then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Actividad agregada", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch("noti", { 
                          mensaje: "Ocurrió un error no se agregó la actividad",
                          color: "error"
                      });
                      this.cancelar();
                    }
                  }
                });
            }
        }
    }
};
</script>
